import { LogLevel } from '@landr/core';
import { ApplicationEnum } from '@landr/core.models';

export type ConfigLogOptions = {
    enabled: boolean;
    separator: string;
    console: {
        enabled: boolean;
        minLevel: LogLevel;
    };
    loggly: {
        enabled: boolean;
        apiKey: string;
        minLevel: LogLevel;
        tags: string;
        maxBundleSize: number;
        consecutiveOccurrences: number;
    };
};

export type Config = {
    appName: ApplicationEnum;
    appVersion: string;

    offlineUrl: string;

    mainAppUrl: string;
    landrEnv: string;

    networkAppUrl: string;
    networkApiBaseUrl: string;
    networkStripePublishableKey: string;
    networkSegmentWriteKey: string;

    fusionAuthDomain: string;
    fusionAuthClientId: string;

    msUserApiBaseUrl: string;
    msProjectsApiBaseUrl: string;
    monolithApiHost: string;
    featureFlagApiBaseUrl: string;

    recaptchaSiteKey: string;

    logOptions: ConfigLogOptions;
};

const FusionAuthClientIdPerTargetEnv = {
    dev: '356d0197-b80e-4ba5-aa2d-90a6d1eefd78',
    demo: 'eb4e2915-ed2a-46cc-b539-41f66d7109ff',
    partner: '767b1c9b-8912-44c8-a446-ec36e48331f6',
};

const LandrEnvPerTargetEnv = {
    dev: 'Development',
    demo: 'Demo',
    partner: 'Partner',
};

const RecaptchaSiteKeyPerTargetEnv = {
    dev: '6Ld8BLcZAAAAAJP0e3PQp-uhBNQveqdV91UGkfeM',
    demo: '6LfFBLcZAAAAAL6f5x3-wQMdWhh1new2RRjJiDog',
    partner: '6Lf2BLcZAAAAAFaA6ia-RyEdqFNbyioug4Ldeu9y',
};

export const loadConfig = async (): Promise<Config> => {
    const importConfig = (await import(
        /* webpackChunkName: "config" */
        /* webpackMode: "lazy-once" */
        /* webpackPrefetch: true */
        /* webpackPreload: true */
        './config.json'
    )) as any;
    const config = importConfig.default as Config;

    // Override with local settings
    if (process.env.NODE_ENV === 'development') {
        const targetEnv = process.env.TARGET_ENV;
        config.mainAppUrl = `https://app-${targetEnv}.landr.com/`;
        config.landrEnv = LandrEnvPerTargetEnv[targetEnv as keyof typeof LandrEnvPerTargetEnv] || 'Development';
        config.appVersion = 'NOT a deployable build!';

        // Network app
        config.networkAppUrl = 'https://local.landr.com:8080/';
        config.networkApiBaseUrl = `https://local.landr.com:8080/api`;
        config.networkSegmentWriteKey = 'd9x3CqTBnxZxDCpk5HiyVlyoSaUzvFD6';
        config.networkStripePublishableKey = 'pk_test_tZiIHeekNeAHzjp5D837Y3r500R3hN9z4Q';

        // FusionAuth
        config.fusionAuthDomain = `https://accounts-${targetEnv}.landr.com`;
        config.fusionAuthClientId =
            FusionAuthClientIdPerTargetEnv[targetEnv as keyof typeof FusionAuthClientIdPerTargetEnv] || '';

        // Base URLs
        config.monolithApiHost = `https://api-${targetEnv}.landr.com`;
        config.msUserApiBaseUrl = `https://api3-${targetEnv}.landr.com/user`;
        config.msProjectsApiBaseUrl = `https://api3-${targetEnv}.landr.com/projects`;
        config.featureFlagApiBaseUrl = `https://api3-${targetEnv}.landr.com/featureflag`;

        // Recaptcha
        config.recaptchaSiteKey =
            RecaptchaSiteKeyPerTargetEnv[targetEnv as keyof typeof RecaptchaSiteKeyPerTargetEnv] || '';

        // Logs
        config.logOptions.console.minLevel = LogLevel.DEBUG;
        config.logOptions.loggly.enabled = false;
    }

    return config;
};
