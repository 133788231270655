import { Log, LogOptions } from '@landr/root-log';
import { User } from '@landr/root-auth';
import { Config } from './config';

export type LogFactoryOptions = {
    serviceKey: string;
    tags: string;
};

// Create log service based on config
export const logFactory = (config: Config, user: User | null) => {
    return (options: LogFactoryOptions): Log => {
        const logOptions: LogOptions = {
            ...config.logOptions,
            deploymentKind: config.landrEnv,
            version: config.appVersion,
            application: config.appName,
            serviceKey: options.serviceKey,
        };
        if (options.tags) {
            logOptions.loggly = Object.assign({}, logOptions.loggly, { tags: options.tags });
        }
        const log = new Log(logOptions);
        log.userProfile = user;

        return log;
    };
};
